import React from "react";
import { useRavePayment } from "react-ravepayment";
import PlainPage from "../../templates/plain-page";

const Content = () => {
  const config = {
    txref: "rave-123456",
    customer_email: "user@example.com",
    customer_phone: "234099940409",
    amount: 100,
    currency: "USD",
    custom_title: "OCCASIO",
    pay_button_text: "Donate",
    custom_logo: "https://occasio.online/img/apple-touch-icon-white-bg.png",
    PBFPubKey: "FLWPUBK_TEST-9b5a6d1c2b7e481c3551d12603b3969c-X",
    hosted_payment: 1,
    redirect_url: "https://occasio.online/successful-donation/",
    // doesn't work
    // onClose: () => {
    //   console.log("close");

    //   window.location.href = "https://occasio.online/donate";
    // },
    // onSuccess: response => {
    //   const chargeResponse = response.data.chargeResponseCode;
    //   if (chargeResponse === "00" || chargeResponse === "0") {
    //     window.location.href = "https://occasio.online/successful-donation";
    //   } else {
    //     window.location.href = "https://occasio.online/donate";
    //   }
    // },
    // callback: response => {
    //   const chargeResponse = response.data.chargeResponseCode;
    //   if (chargeResponse === "00" || chargeResponse === "0") {
    //     window.location.href = "https://occasio.online/successful-donation";
    //   } else {
    //     window.location.href = "https://occasio.online/donate";
    //   }
    // },
    // country: "NG",
    //       // onclose: function() {
    //       //   window.location.href = SITE_NAME + "donate"; // doesn't work, triggers redirect_url
    //       // }
    //       callback: function(response: any) {
    //         // doesn't trigger
    //         // (txref = response.data.txRef),
    //         const chargeResponse = response.data.chargeResponseCode;
    //         if (chargeResponse == "00" || chargeResponse == "0") {
    //           window.location.href = "https://occasio.online/successful-donation";
    //         } else {
    //           window.location.href = "https://occasio.online/donate";
    //         }
    //       }
  };
  const configNG = {
    txref: "rave-123456",
    customer_email: "user@example.com",
    customer_phone: "234099940409",
    amount: 100,
    currency: "GHS",
    custom_title: "OCCASIO",
    pay_button_text: "Donate",
    custom_logo: "https://occasio.online/img/apple-touch-icon-white-bg.png",
    PBFPubKey: "FLWPUBK_TEST-9b5a6d1c2b7e481c3551d12603b3969c-X",
    hosted_payment: 1,
    redirect_url: "https://occasio.online/successful-donation/",
  };

  const { initializePayment } = useRavePayment(config);
  const { initializePayment: initializePaymentNG } = useRavePayment(configNG);

  // doesn't work with initializePayment(onSuccess, onClose);
  // const onClose = () => {
  //   window.location.href = "https://occasio.online/donate";
  // };

  // const onSuccess = response => {
  //   const chargeResponse = response.data.chargeResponseCode;
  //   if (chargeResponse === "00" || chargeResponse === "0") {
  //     window.location.href = "https://occasio.online/successful-donation";
  //   } else {
  //     window.location.href = "https://occasio.online/donate";
  //   }
  // };

  return (
    <div>
      {/* <RaveProvider {...config}>
        <RavePaymentButton className="button is-primary">
          Pay 2000
        </RavePaymentButton>
      </RaveProvider> */}
      <button
        type="button"
        className="button is-primary"
        onClick={() => {
          initializePaymentNG();
        }}
      >
        pay 100 GHS
      </button>
      <br />
      <br />
      <button
        type="button"
        className="button is-primary"
        onClick={() => {
          initializePayment();
        }}
      >
        pay 100 USD
      </button>
    </div>
  );
};

// server side solution, callback paramaters doesn't trigger
// firebaseFunc
//             .httpsCallable("getFlutterWaveUrl")({
//               id: "1234523145",
//               amount: 100,
//               email: "abc@abc.com",
//               phone: "+11111",
//             })
//             .then(res => {
//               console.log(res);
//               if (res.data.status === "success") {
//                 window.open(res.data.data.link, "_blank");

//                 // window.location.assign(res.data.data.link);
//                 // window.location = res.data.data.link;
//                 // window.open(res.data.data.link, "_blank");
//               }
//             })
//             .catch(error => alert(error))

// export const getFlutterWaveUrl = functions.https.onCall((data, context) => {
//   return fetch("https://api.ravepay.co/flwv3-pug/getpaidx/api/v2/hosted/pay", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json"
//     },
//     body: JSON.stringify({
//       txref: data.id,
//       PBFPubKey: "FLWPUBK_TEST-9b5a6d1c2b7e481c3551d12603b3969c-X",
//       amount: data.amount,
//       currency: "USD",
//       customer_email: data.email,
//       customer_phone: data.phone,
//       custom_title: "OCCASIO",
//       pay_button_text: "Donate",
//       custom_logo: "https://occasio.online/img/apple-touch-icon-white-bg.png",
//       // redirect_url: "https://occasio.online/donate",
//       // onclose: function() {
//       //   window.location.href = SITE_NAME + "donate"; // doesn't work, triggers redirect_url
//       // }
//       callback: function(response: any) {
//         // doesn't trigger
//         // (txref = response.data.txRef),
//         const chargeResponse = response.data.chargeResponseCode;
//         if (chargeResponse == "00" || chargeResponse == "0") {
//           window.location.href = "https://occasio.online/successful-donation";
//         } else {
//           window.location.href = "https://occasio.online/donate";
//         }
//       }
//       // payment_options: "card,mobilemoneyghana,ussd,mpesa"
//       // country: "" // TODO Check if needed
//     })
//   })
//     .then((response: any) => {
//       if (response.ok) {
//         return response.json();
//       } else {
//         console.log(response);
//         throw new Error("Can't connect to FlutterWave");
//       }
//     })
//     .then((res: any) => {
//       return res;
//     })
//     .catch((error: any) => {
//       console.log(error);
//     });
//   return 0;
// });

const Flutterwave = ({ location }) => {
  return <PlainPage Content={Content} location={location} isCentred />;
};

export default Flutterwave;
